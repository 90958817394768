import React from 'react';
import { Calendar, Users, BookOpen, Video, Users2, Target, BarChart, ArrowRight } from 'lucide-react';

interface ProgramTimelineProps {
  onApplyClick: () => void;
}

const ProgramTimeline: React.FC<ProgramTimelineProps> = ({ onApplyClick }) => {
  const months = [
    {
      title: 'Foundations of Export Business',
      color: 'blue',
      icon: BookOpen,
      weeks: [
        { title: 'LIVE Virtual Class - Introduction to Export Business', type: 'live' },
        { title: 'Action Plan Activities & Progress Tracking', type: 'action' },
        { title: 'Alumni Session - Success Stories & Lessons Learned', type: 'alumni' },
        { title: 'LIVE Virtual Class - Export Business Planning', type: 'live' },
        { title: 'Expert Session - Export Financing', type: 'expert' }
      ]
    },
    {
      title: 'Digital Strategies & Buyer Connection',
      color: 'purple',
      icon: Target,
      weeks: [
        { title: 'LIVE Virtual Class - Export Business Operations', type: 'live' },
        { title: 'Action Plan Activities & Mentorship Review Sessions', type: 'action' },
        { title: 'Alumni Session - International Buyer Sourcing', type: 'alumni' },
        { title: 'LIVE Virtual Class - Digital & Global E-Commerce Marketing', type: 'live' },
        { title: 'Expert Session - Export Contract Analysis', type: 'expert' }
      ]
    },
    {
      title: 'Advanced Strategies & Business Growth',
      color: 'green',
      icon: BarChart,
      weeks: [
        { title: 'LIVE Virtual Class - Sourcing Mastery (Agro & Metals)', type: 'live' },
        { title: 'Action Plan Activities & Mentorship Review Sessions', type: 'action' },
        { title: 'Alumni Session - Global Logistics & Export Freight Documentation', type: 'alumni' },
        { title: 'LIVE Virtual Class - Export Business Plan Finalization', type: 'live' },
        { title: 'Expert Session - Global Logistics & Documentation Analysis', type: 'expert' }
      ]
    }
  ];

  // Explicit color mapping to avoid Tailwind's dynamic class issue
  const colorMap = {
    blue: 'bg-blue-600',
    purple: 'bg-purple-600',
    green: 'bg-green-600'
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case 'live':
        return <Video className="h-5 w-5" />;
      case 'action':
        return <Target className="h-5 w-5" />;
      case 'alumni':
        return <Users2 className="h-5 w-5" />;
      case 'expert':
        return <BookOpen className="h-5 w-5" />;
      default:
        return null;
    }
  };

  const getTypeColor = (type) => {
    switch (type) {
      case 'live':
        return 'bg-blue-100 text-blue-600';
      case 'action':
        return 'bg-purple-100 text-purple-600';
      case 'alumni':
        return 'bg-green-100 text-green-600';
      case 'expert':
        return 'bg-orange-100 text-orange-600';
      default:
        return 'bg-gray-100 text-gray-600';
    }
  };

  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">Program Timeline</h2>
          <p className="mt-4 text-xl text-gray-600">
            A comprehensive 3-month journey to export mastery
          </p>
        </div>

        <div className="flex items-center justify-center gap-8 mb-12">
          <div className="flex items-center bg-blue-50 rounded-full px-4 py-2">
            <Calendar className="h-5 w-5 text-blue-600 mr-2" />
            <span className="text-blue-800">3 months duration</span>
          </div>
          <div className="flex items-center bg-green-50 rounded-full px-4 py-2">
            <Users className="h-5 w-5 text-green-600 mr-2" />
            <span className="text-green-800">Rolling basis enrollment</span>
          </div>
        </div>

        <div className="grid gap-8">
          {months.map((month, monthIndex) => (
            <div key={monthIndex} className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
              {/* Month Header with Fixed Colors */}
              <div className={`${colorMap[month.color]} p-6 flex items-center justify-between`}>
                <div className="flex items-center">
                  <month.icon className="h-8 w-8 text-white" />
                  <h3 className="ml-4 text-2xl font-bold text-white">
                    Month {monthIndex + 1}: {month.title}
                  </h3>
                </div>
              </div>
              
              {/* Weeks */}
              <div className="p-6">
                <div className="grid gap-4">
                  {month.weeks.map((week, weekIndex) => (
                    <div 
                      key={weekIndex}
                      className="flex items-center bg-gray-50 rounded-xl p-4 transform hover:scale-[1.02] transition-transform duration-300"
                    >
                      <div className={`rounded-full p-3 mr-4 ${getTypeColor(week.type)}`}>
                        {getTypeIcon(week.type)}
                      </div>
                      <div>
                        <div className="text-sm text-gray-500">Week {weekIndex + 1}</div>
                        <div className="font-medium text-gray-900">{week.title}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="mt-12 bg-gradient-to-r from-blue-600 to-blue-700 rounded-2xl p-8 text-center">
          <h3 className="text-2xl font-bold text-white mb-4">Ready to Start Your Export Journey?</h3>
          <p className="text-blue-100 mb-6">
            Join our next cohort and transform your export business aspirations into reality
          </p>
          <button
            onClick={onApplyClick}
            className="inline-flex items-center px-6 py-3 border-2 border-white text-white rounded-lg hover:bg-white hover:text-blue-600 transition-all duration-300"
          >
            Apply Now
          </button>
        </div>
      </div>
    </section>
  );
};

export default ProgramTimeline;