import React from 'react';
import { Download } from 'lucide-react';

const DownloadEMPDocs = () => {
  return (
    <section className="py-16 bg-gray-50 text-center">
      <div className="max-w-3xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-gray-900">Download EMP Resources</h2>
        <p className="mt-4 text-lg text-gray-600">
          Get access to essential EMP program materials. Click below to download the documents.
        </p>
        
        <div className="mt-6 flex flex-col sm:flex-row justify-center gap-6">
          {/* EMP Program Details Button */}
          <a
            href="https://drive.google.com/file/d/1whueHXDXSH1uthrOvnl3DwoykooDGnji/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-700 transition-all"
          >
            <Download className="h-5 w-5 mr-2" /> EMP Program Details
          </a>
          
          {/* EMP Program Curriculum Button */}
          <a
            href="https://drive.google.com/file/d/1zmF2ZPiD0A5pk9Um0HJfpM5EQ4XR5Owg/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center px-6 py-3 bg-green-600 text-white rounded-lg shadow-lg hover:bg-green-700 transition-all"
          >
            <Download className="h-5 w-5 mr-2" /> EMP Program Curriculum
          </a>
        </div>
      </div>
    </section>
  );
};

export default DownloadEMPDocs;
