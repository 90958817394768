import React, { useState } from 'react';
import { Calendar, Users, Target, CheckCircle, ArrowRight } from 'lucide-react';
import ProgramFeatures from '../../components/emp/ProgramFeatures';
import ProgramTimeline from '../../components/emp/ProgramTimeline';
import DownloadEMPDocs from '../../components/emp/DownloadEMPDocs';
import EMPApplicationForm from '../../components/emp/EMPApplicationForm';
import EMPFAQ from '../../components/emp/EMPFAQ';

const ExportMentorshipProgram = () => {
  const [isApplicationFormOpen, setIsApplicationFormOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gray-50 pt-20">
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "EducationalProgram",
          "name": "Export Mentorship Program",
          "description": "3-month intensive program to equip entrepreneurs with export skills and knowledge",
          "provider": {
            "@type": "Organization",
            "name": "Lagos School of Export Management"
          },
          "duration": "P3M",
          "timeToComplete": "3 months",
          "educationalProgramMode": "Online or in-person",
          "offers": {
            "@type": "Offer",
            "url": window.location.href
          }
        })}
      </script>
      {/* Hero Section */}
      <div className="relative">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src="https://towebornottoweb.com/wp-content/uploads/2023/01/Export-Multichannel-Strategy-Design-1-e1662553193297.jpg"
            alt="Export Mentorship"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-900/80" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="max-w-3xl">
            <span className="inline-flex items-center px-4 py-2 rounded-full text-sm font-medium bg-blue-100 text-blue-800 mb-4">
              Premium Export Training Program
            </span>
            <h1 className="text-4xl font-bold text-white sm:text-5xl md:text-6xl mb-6">
              Export Mentorship Program
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              Your gateway to success in the global export market
            </p>
            <div className="flex flex-wrap gap-6 mb-8">
              <div className="flex items-center bg-white/10 rounded-full px-4 py-2 text-white">
                <Calendar className="h-5 w-5 mr-2" />
                3 Months Duration
              </div>
              <div className="flex items-center bg-white/10 rounded-full px-4 py-2 text-white">
                <Users className="h-5 w-5 mr-2" />
                Limited to 20 Participants
              </div>
            </div>
            <button
              onClick={() => setIsApplicationFormOpen(true)}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 transition-colors duration-300"
            >
              Apply Now
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Program Description */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
          <div>
            <div className="bg-white rounded-2xl shadow-xl p-8">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                About the Program
              </h2>
              <p className="text-lg text-gray-600 mb-8">
                The <strong>Export Mentorship Program (EMP)</strong> is a 3-month intensive program designed by the <strong>Lagos School of Export Management</strong> to equip entrepreneurs and business owners with the skills, knowledge, and network needed to succeed in the export industry.
              </p>
              
              <div className="relative mb-12">
                <img
                  src="https://th.bing.com/th/id/OIP.YL9iA27RfYpViJjz4VMe-wHaEK?rs=1&pid=ImgDetMain"
                  alt="Program Objective"
                  className="rounded-xl shadow-lg"
                />
                <div className="absolute -bottom-6 -right-6 bg-blue-600 rounded-xl p-6 shadow-xl">
                  <Target className="h-8 w-8 text-white" />
                </div>
              </div>

              <div className="bg-gray-50 rounded-xl p-6 mb-8">
                <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                  <Target className="h-6 w-6 text-blue-600 mr-2" />
                  Program Objective
                </h3>
                <p className="text-gray-600">
                  Transform participants into successful exporters through hands-on mentorship and practical training, providing them with the tools and connections needed to thrive in international trade.
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-white rounded-2xl shadow-xl p-8">
              <h3 className="text-2xl font-bold text-gray-900 mb-6">
                What You'll Learn
              </h3>
              
              <div className="relative mb-12">
                <img
                  src="https://th.bing.com/th/id/OIP.MF0sqh_tyfvjrVWEi6n6aQHaE7?rs=1&pid=ImgDetMain"
                  alt="Learning Objectives"
                  className="rounded-xl shadow-lg"
                />
                <div className="absolute -bottom-6 -left-6 bg-gradient-to-br from-blue-600 to-blue-700 rounded-xl p-6 shadow-xl">
                  <CheckCircle className="h-8 w-8 text-white" />
                </div>
              </div>

              <div className="space-y-6 mt-8">
                <div className="bg-blue-50 rounded-xl p-6">
                  <h4 className="font-semibold text-gray-900 mb-4">Export Market Analysis</h4>
                  <div className="grid gap-3">
                    <div className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-blue-600 mr-3" />
                      <span className="text-gray-600">Market research methodologies</span>
                    </div>
                    <div className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-blue-600 mr-3" />
                      <span className="text-gray-600">Demand analysis techniques</span>
                    </div>
                  </div>
                </div>

                <div className="bg-green-50 rounded-xl p-6">
                  <h4 className="font-semibold text-gray-900 mb-4">Documentation & Compliance</h4>
                  <div className="grid gap-3">
                    <div className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-green-600 mr-3" />
                      <span className="text-gray-600">International trade documentation</span>
                    </div>
                    <div className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-green-600 mr-3" />
                      <span className="text-gray-600">Regulatory compliance standards</span>
                    </div>
                  </div>
                </div>

                <div className="bg-purple-50 rounded-xl p-6">
                  <h4 className="font-semibold text-gray-900 mb-4">Financial Management</h4>
                  <div className="grid gap-3">
                    <div className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-600 mr-3" />
                      <span className="text-gray-600">Export financing methods</span>
                    </div>
                    <div className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-600 mr-3" />
                      <span className="text-gray-600">Risk management strategies</span>
                    </div>
                  </div>
                </div>

                <div className="bg-orange-50 rounded-xl p-6">
                  <h4 className="font-semibold text-gray-900 mb-4">Supply Chain Optimization</h4>
                  <div className="grid gap-3">
                    <div className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-orange-600 mr-3" />
                      <span className="text-gray-600">Logistics management</span>
                    </div>
                    <div className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-orange-600 mr-3" />
                      <span className="text-gray-600">Quality control systems</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Program Timeline */}
      <ProgramTimeline onApplyClick={() => setIsApplicationFormOpen(true)} />

      {/* Program Features */}
      <ProgramFeatures />

      <DownloadEMPDocs />

      <EMPApplicationForm
        isOpen={isApplicationFormOpen}
        onClose={() => setIsApplicationFormOpen(false)}
      />

      <EMPFAQ />
    </div>
  );
};

export default ExportMentorshipProgram;