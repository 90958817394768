import React from 'react';
import { ArrowRight } from 'lucide-react';

const features = [
  {
    title: 'One-on-One Mentorship',
    description: 'Personal guidance from experienced export professionals',
    image: 'https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
    benefits: [
      'Personalized guidance and feedback',
      'Direct access to industry experts',
      'Customized learning path'
    ]
  },
  {
    title: 'Practical Workshops',
    description: 'Hands-on sessions with real export documentation and procedures',
    image: 'https://www.nahcoaviance.com/wp-content/uploads/2021/08/warehouse.jpg',
    benefits: [
      'Real-world documentation practice',
      'Interactive learning sessions',
      'Case study analysis'
    ]
  },
  {
    title: 'Industry Network',
    description: 'Access to a network of successful exporters and trade partners',
    image: 'https://scontent-los2-1.xx.fbcdn.net/v/t39.30808-6/482021853_9118276191554816_6457331358731829553_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=86c6b0&_nc_ohc=-Y4d7dfQMXYQ7kNvgG9mUkD&_nc_oc=AdmR-DG8MGl30B7GtRmIuG9QqmDYYR9SYHVzQSqREUAxh7SXGIn-v89kvccqHvCNMVE&_nc_zt=23&_nc_ht=scontent-los2-1.xx&_nc_gid=evod74RywE84JcmidzRCFA&oh=00_AYEMxhvY8x9LO5WWyzr53w5KZ5tzGUgIv8DjoovCKiDGhA&oe=67E73A3E',
    benefits: [
      'Connect with industry leaders',
      'Join export community',
      'Networking events access'
    ]
  },
  {
    title: 'Market Access',
    description: 'Direct connections to international buyers and markets',
    image: 'https://th.bing.com/th/id/OIP.CQrfCkctcKONTvyBGA-hrQHaFP?rs=1&pid=ImgDetMain',
    benefits: [
      'International market insights',
      'Buyer connections',
      'Trade opportunity access'
    ]
  }
];

const ProgramFeatures = () => {
  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Program Features</h2>
          <p className="mt-4 text-xl text-gray-600">
            Comprehensive support to launch your export business
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl shadow-xl overflow-hidden hover:shadow-2xl transition-shadow duration-300"
            >
              <div className="relative h-64">
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent" />
                <div className="absolute bottom-4 left-6">
                  <h3 className="text-2xl font-bold text-white">{feature.title}</h3>
                </div>
              </div>
              
              <div className="p-6">
                <p className="text-gray-600 mb-6">{feature.description}</p>
                
                <div className="space-y-3 mb-6">
                  {feature.benefits.map((benefit, idx) => (
                    <div key={idx} className="flex items-center">
                      <div className="h-2 w-2 bg-blue-600 rounded-full mr-3" />
                      <span className="text-gray-700">{benefit}</span>
                    </div>
                  ))}
                </div>

                <button className="inline-flex items-center text-blue-600 hover:text-blue-700 font-medium group">
                  Learn More
                  <ArrowRight className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform duration-300" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgramFeatures;