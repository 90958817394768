import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { Users, Globe, DollarSign, BookOpen, GraduationCap, Building } from 'lucide-react';

interface StatItemProps {
  icon: React.ElementType;
  value: number;
  suffix?: string;
  prefix?: string;
  label: string;
}

const StatItem: React.FC<StatItemProps> = ({ icon: Icon, value, suffix, prefix, label }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className="relative p-6 bg-white rounded-2xl shadow-lg transform hover:-translate-y-1 transition-transform duration-300">
      <div className="absolute -top-4 left-6 w-12 h-12 bg-blue-600 rounded-lg flex items-center justify-center shadow-lg">
        <Icon className="h-6 w-6 text-white" />
      </div>
      <div className="pt-8">
        <div className="text-3xl font-bold text-gray-900 flex items-baseline">
          {prefix}
          {inView ? (
            <CountUp end={value} duration={2.5} separator="," />
          ) : (
            '0'
          )}
          {suffix}
        </div>
        <p className="mt-2 text-sm text-gray-600">{label}</p>
      </div>
      <div className="absolute bottom-0 right-0 h-2 w-full bg-gradient-to-r from-blue-600 to-blue-400 rounded-b-2xl" />
    </div>
  );
};

const Stats = () => {
  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
            Our Impact in Numbers
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Transforming the export industry through education and empowerment
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <StatItem
            icon={Users}
            value={1000}
            suffix="+"
            label="Students Trained & Counting"
          />
          <StatItem
            icon={Globe}
            value={20}
            suffix="+"
            label="Countries Reached"
          />
          <StatItem
            icon={DollarSign}
            value={1000000}
            prefix="$"
            suffix="+"
            label="Revenue Generated by Students"
          />
          <StatItem
            icon={BookOpen}
            value={20}
            suffix="+"
            label="Publications in Export Sector"
          />
          <StatItem
            icon={GraduationCap}
            value={95}
            suffix="%"
            label="Employment Rate"
          />
          <StatItem
            icon={Building}
            value={50}
            suffix="+"
            label="Corporate Partners"
          />
        </div>

        <div className="mt-16 bg-white rounded-2xl p-8 shadow-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-900">Growing Impact</h3>
              <p className="text-gray-600">
                Our numbers reflect our commitment to excellence in export education and the success of our students in the global market.
              </p>
              <ul className="space-y-2">
                <li className="flex items-center text-gray-600">
                  <div className="w-2 h-2 bg-blue-600 rounded-full mr-2" />
                  Continuous growth in student enrollment
                </li>
                <li className="flex items-center text-gray-600">
                  <div className="w-2 h-2 bg-blue-600 rounded-full mr-2" />
                  Expanding international presence
                </li>
                <li className="flex items-center text-gray-600">
                  <div className="w-2 h-2 bg-blue-600 rounded-full mr-2" />
                  Increasing industry partnerships
                </li>
              </ul>
            </div>
            <div className="relative">
              <div className="h-full w-full bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl p-6">
                <div className="space-y-4">
                  {[75, 85, 95].map((value, index) => (
                    <div key={index} className="relative pt-1">
                      <div className="flex mb-2 items-center justify-between">
                        <div className="text-sm font-semibold text-gray-700">
                          {['Student Satisfaction', 'Course Completion', 'Career Success'][index]}
                        </div>
                        <div className="text-sm font-semibold text-blue-600">
                          {value}%
                        </div>
                      </div>
                      <div className="overflow-hidden h-2 text-xs flex rounded bg-blue-200">
                        <div
                          style={{ width: `${value}%` }}
                          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;