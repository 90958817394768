import React, { useState } from "react";
import { motion } from "framer-motion";
import { ChevronDown } from "lucide-react";

const faqData = [
  {
    question: "What is the Export Mentorship Program (EMP)?",
    answer:
      "EMP is designed to mentor prospective exporters or active exporters in the business of exporting, and guide them in scaling their export operations successfully.",
  },
  {
    question: "Who is eligible to apply?",
    answer:
      "Anyone interested in export trade, including startups, SMEs, and established businesses looking to expand internationally.",
  },
  {
    question: "How long does the program last?",
    answer: "The mentorship program runs for 12 weeks, covering various aspects of export strategies.",
  },
  {
    question: "Is there a fee for the program?",
    answer:
      "Yes, there is a nominal fee to cover resources and mentorship sessions. This fee is nothing compared to the value attached to the program.",
  },
  {
    question: "Will I get a certificate upon completion?",
    answer:
      "Yes! Participants who successfully complete the program will receive a certificate of participation.",
  },
  {
    question: "Are mentorship sessions conducted online or in-person?",
    answer:
      "The sessions are conducted online via live webinars.",
  },
  {
    question: "Can I apply if I have no prior export experience?",
    answer:
      "Absolutely! The program is designed to accommodate beginners as well as experienced exporters.",
  },
  {
    question: "How do I apply for the program?",
    answer:
      "Click the ‘Apply Now’ button on the EMP page and complete the application form. Our team will review your submission and notify you.",
  },
  {
    question: "What topics are covered in the mentorship program?",
    answer:
      "The curriculum includes export documentation, market entry strategies, compliance, logistics, financing, and more.",
  },
  {
    question: "How can I contact support for more inquiries?",
    answer:
      "You can reach out to us via email, emp@lsem.org.ng or click on the WhatsApp button to chat with us.",
  },
  {
    question: "What if I have more questions?",
    answer: "You can download the EMP resource guide and curriculum for more information.",
  },
];

const EMPFAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-xl rounded-2xl mt-10 mb-16">
      <h2 className="text-3xl font-bold text-center text-blue-700 mb-6">
        Frequently Asked Questions
      </h2>
      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <div key={index} className="border-b border-gray-200 pb-3">
            <button
              className="w-full flex justify-between items-center py-3 text-lg font-semibold text-gray-800 hover:text-blue-700"
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <ChevronDown
                className={`transition-transform transform ${
                  openIndex === index ? "rotate-180" : ""
                }`}
              />
            </button>
            {openIndex === index && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                className="text-gray-600 mt-2 pl-4 border-l-4 border-blue-500"
              >
                {faq.answer}
              </motion.div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EMPFAQ;
